<template>
  <div class="cascader-container-product">
    <el-cascader
      :options="productTypeList"
      :props="{ multiple: true }"
      collapse-tags
      v-model="chooseProduct"
      :show-all-levels="false"
      @change="getCheckedNodes"
    >
    </el-cascader>
  
  </div>
</template>

<script>
import api from "@/fetch/api";

export default {
  name: 'ProductTypes',
  props:{},
  components: {},
  data() {
    return {
      productListTemp:[],
      productTypeList: [],      // 用于级联选择器的数据
      sortProductList:[],       // 已选择的选项置顶
      parentProductList:[],
      chooseProduct: [],        // 用户选择的
      productTypeListAPI: [],  // 传给后端的只包含子集的列表
      inputType:'',
    };
  },
  created() {
  },
  mounted() {
    this.setProductType()
  },
  updated() {
    this.getCheckedNodes()
  },
  methods: {
    //   获取全部产品类型
    setProductType() {
      api.getProductTypeIndex({}).then(res => {
        this. productTypeListTemp = JSON.parse(JSON.stringify(res.data))
        this.productTypeList = this.dataToCascader(this.productTypeListTemp,'productType2','productType3', 'typeId', 'typeName')[0]
        // this.sortProductList = this.productTypeList
        // console.log('初始化this.productTypeList ->',this.productTypeList)
        // console.log('this.parentProductList ->',this.parentProductList)
      })
    },

//     拆解合成单个对象
    oneTwoThree(one, param1, param2, id, name){
      let itemOne = {}
      itemOne.children = []
      itemOne.value = one[id]
      itemOne.label = one[name]
      if (one[param1]) {
        one[param1].forEach(two => {
          let itemTwo = {}
          itemTwo.children = []
          itemTwo.value = two[id]
          itemTwo.label = two[name]
          if (two[param2]) {
            two[param2].forEach(three => {
              let itemThree = {}
              itemThree.value = three[id]
              itemThree.label = three[name]
              itemTwo.children.push(itemThree)
            })
          }
          itemOne.children.push(itemTwo)
        })
      }
      return itemOne
    },

//     匹配接收数据和级联选择器数据
    dataToCascader(ObjList, level1, level2, id, name){
      let optionList = []
      let choseList = []
      let unChoseList = []
      ObjList.forEach(one=>{
        if(one.typeName){
          // console.log('one[id],this.parentProductList ->',one[id],this.parentProductList)
          if (this.parentProductList.length !== 0 && this.parentProductList.indexOf(one[id])) {
            // console.log('加入choseList')
            choseList.push(this.oneTwoThree(one, level1, level2, id, name))
          } else {
            // console.log('加入unchoseList')
            unChoseList.push(this.oneTwoThree(one, level1, level2, id, name))
          }
        }
      })
      choseList.length !== 0 ? optionList.push(choseList) : console.log('')
      unChoseList.length !== 0 ? optionList.push(unChoseList) : console.log('')
      // console.log('optionList ->', optionList)
      return optionList
    },

//     获取子集列表
    getCheckedNodes(){
      this.productTypeListAPI = []
      let allParentId = []
      this.parentProductList = []
      this.chooseProduct.forEach(item=>{
        if(item[2]){
          this.productTypeListAPI.push(item[2])
        }else if(item[1]){
          this.productTypeListAPI.push(item[1])
        }else{
          this.productTypeListAPI.push(item[0])
        }
        allParentId.push(item[0])
        allParentId.forEach(id=>{
          if(this.parentProductList.indexOf(id) === -1){
            this.parentProductList.push(id)
          }
        })
      })
      // this.sortProductList = this.dataToCascader(this.productTypeList,'children','children','value', 'label')[0]
      // this.productTypeList = this.sortProductList
      // console.log('点击后this.sortProductList ->',this.sortProductList)
      // console.log('this.parentProductList ->',this.parentProductList)
      // let newType = this.inputType.split(',')
      let input = []
      this.inputType === ''? input = null : input = this.inputType.split(',')
      let types = [this.productTypeListAPI,input]
      // console.log('types ->',types)
      this.$emit('getType',types)
    },
    
  }
}
;
</script>

<style lang="scss" scoped>
.cascader-container-product {
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  background-color: inherit;
  
  .el-cascader-panel{
    display: flex;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .el-cascader-menu:last-child .el-cascader-node {
    padding-right: 20px;
  }
  
  .el-cascader-menu__list {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 6px 0;
    list-style: none;
    box-sizing: border-box;
  }
  
  .el-cascader{
    min-width: 200px;
    margin-right: 20px;
  }
  
  /*.el-input{
    width: 300px;
  }*/
}
</style>