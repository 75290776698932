<template>
  <div class="refuse-div">
    <div class="one-part">
      <div
        class="one-left"
        style="margin-top: 24px;"
      >
        <!-- id -->
        <div class="one-line">
          <label for="contactName">Id ：</label>
          <!-- <el-input
            v-model.trim="searchId"
            class="text-line"
            clearable
          ></el-input> -->
          <input
            type="text"
            id="contactName"
            v-model.trim="searchId"
            clearable
          >
          <!-- <input
            type="text"
            placeholder="单行输入"
            v-model="searchId"
            class="text-line"
          > -->
        </div>
        <!-- 业务类型 -->
        <!-- <div class="product-type" v-if="false">
          <label>{{ $t('ProductType') }}：</label>
          <ProductTypes
            class="selector"
            v-if="productTypeDialog"
            @getType="getProductType"
          ></ProductTypes>
        </div> -->

        <div class="times-lines">
          <label>{{ $t('ReportingTime') }}：</label>
          <el-date-picker
            v-model="reportTime"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('ChooseDate')"
          >
          </el-date-picker>
        </div>
      </div>

      <!-- <div class="one-times">
        <div class="times-line" v-if="false">
          <label>{{ $t('CreateTime') }}：</label>
          <el-date-picker
            v-model="createTime"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('ChooseDate')"
          >
          </el-date-picker>
        </div>
        
      </div> -->

    </div>
    <!-- 右边搜索 -->
    <div class="search-lines">
      <el-button @click="searchReportPosts">{{ $t('Search') }}</el-button>
      <!-- <input
        type="button"
        class="sousuo-line"
        value="搜索"
        @click="searchReportPosts()"
      > -->
    </div>

    <!-- 第二行第一部分 -->
    <div class="all-line">
      <div class="all-one-line">
        <div
          @click="replace('')"
          :class="initRoportPost.postType===''?'active':''"
          class="all"
        >{{ $t('AllData') }}</div>
        <div
          @click="replace(2)"
          :class="initRoportPost.postType===2?'active':''"
          class="parter"
        >{{ $t('CommerceCoop') }}</div>
        <div
          @click="replace(0)"
          :class="initRoportPost.postType===0?'active':''"
          class="maijia"
        >{{ $t('Buyers') }}</div>
        <div
          @click="replace(1)"
          :class="initRoportPost.postType===1?'active':''"
          class="selelr"
        >{{ $t('Sellers') }}</div>
      </div>
      <!-- 第二行第二部分 -->
      <div class="two-part">
        <div
          @click="select('')"
          :class="initRoportPost.status===''?'active':''"
          class="two-all"
        >{{ $t('AllData') }}</div>
        <div
          @click="select(0)"
          :class="initRoportPost.status===0?'active':''"
          class="two-sh"
        >{{ $t('Auditing') }}</div>
        <!-- <div
          @click="select(1)"
          :class="initRoportPost.status===1?'active':''"
          class="two-passed"
        >{{ $t('delete') }}</div> -->
        <div
          @click="select(2)"
          :class="initRoportPost.status===2?'active':''"
          class="refuses"
        >{{$t('DoInvalidReport')}}</div>
      </div>
    </div>
    <!-- 表格 -->

    <el-table
      border
      :data="tableData"
      class="table-border"
    >
      <!-- :cell-style="getColor" -->
      <!--来自-->
      <el-table-column
        prop="chamberName"
        :label="$t('From')"
        width="200"
        align="center"
      >
      </el-table-column>
      <!--标题-->
      <el-table-column
        prop="postTitle"
        :label="$t('Title')"
        :formatter="formatTitle"
        width="320"
        align="center"
      >
      </el-table-column>
      <!--帖子类型-->
      <el-table-column
        prop="productTypes"
        :label="$t('PostType')"
        width="180"
        align="center"
      >
        <template slot-scope="scope">{{ threePostType(scope.row.postType) }}</template>
      </el-table-column>
      <!--举报时间-->
      <el-table-column
        prop="reportTime"
        :label="$t('ReportingTime')"
        width="160"
        align="center"
      >
      </el-table-column>
      <!--审核时间-->
      <el-table-column
        prop="reviewTime"
        :label="$t('reviewTime')"
        width="160"
        align="center"
      >
      </el-table-column>
      <!--举报理由-->
      <el-table-column
        prop="additional"
        :label="$t('ReportReason')"
        width="220"
        align="center"
        :show-overflow-tooltip='true'
      >
      </el-table-column>
      <!--状态-->
      <el-table-column
        :label="$t('Status')"
        prop="status"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="getColor[scope.row.status]">{{ getPostType(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <!--操作-->
      <el-table-column
        :label="$t('Operation')"
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <!-- status状态：0:待审核、1:举报成功、2:举报失败 -->
          <!--详情-->
          <el-button
            size="mini"
            type="text"
            @click="delatied(scope.row)"
          >{{ $t('Detail') }}</el-button>
          <!-- 从本商会删除该帖、从所有系统撤回该帖 -->
          <el-button
            v-if="scope.row.status === 0"
            size="mini"
            type="text"
            style="color: red"
            @click="removePostIds(scope.row)"
          >{{ scope.row.chamberId === chamberId ? $t('RevokeAll') : $t('RevokeThis') }}</el-button>
          <!--举报无效-->
          <el-button
            v-if="scope.row.status === 0"
            size="mini"
            type="text"
            style="color: red"
            @click="invalidReport(scope.row.reportId)"
          >{{$t('DoInvalidReport')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="fen-pages">
      <el-pagination
        layout="prev, pager, next"
        background
        :current-page="currentPage"
        :page-size="pageSize"
        :total="reportTotal"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import api from "@/fetch/api";
import ProductTypes from "@/common/ProductTypes.vue";
import mixin from "@/assets/js/mixin";
export default {
  mixins: [mixin],
  name: "ReportPost",
  components: { ProductTypes },
  data() {
    // 读取本地个人信息
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      chamberId: data.chamberId, // 商会id
      productTypeDialog: true,
      right: Number(data.postPermission),
      // 通过状态改变字体颜色 数组
      getColor: [
        "color: #CBA43F",
        "color:#81B337",
        "color: #FF0000",
        "color: #000",
      ],
      //动态绑定时间值
      createTime: "",
      reportTime: "",
      // 搜索绑定的属性 搜索后续传值
      searchId: "",
      // 业务类型选择器
      productTypes: [],
      // 当前页数
      currentPage: 1,
      // 举报帖子信息列表总条数 总条目数
      reportTotal: 9,
      index: 0,
      // 业务类型搜索
      postType: "",
      //显示的页数
      pageSize: 10,
      // 业务类型
      // businessType: [],
      // businessTypeIds: [],
      // 详情按钮样式
      detailedInformation: {
        backgroundColor: "#fff",
        color: "#004C9E",
      },
      //表格数据
      tableData: [],
      initRoportPost: {
        currentAdmin: "",
        page: 1,
        postId: "",
        reportId: "",
        userId: "",
        productTypes: "",
        createTime: "",
        reportTime: "",
        status: "", //1已通过【人工通过】 2未审核【有敏感词】 3被拒绝 全部：该字段不传 0自动通过
        postType: "", //0待审核 1删除 2举报无效 全部：该字段不传
        type: 0, //1.本系统删除 2.全系统删除
        postOwnerId: "",
      },
    };
  },
  mounted() {
    this.initRoportPostData(1);
  },
  created() {},
  methods: {
    /**
     * 获取业务类型
     */
    // getProductTypeIndex() {
    //   api.getProductTypeIndex({}).then((res) => {
    //     // 处理接口数据
    //     const copyData = JSON.parse(JSON.stringify(res.data));
    //     if (copyData.length) {
    //       copyData.map((level1) => {
    //         level1.value = level1.typeId;
    //         level1.label = level1.typeName;
    //         level1.children = level1.productType2;
    //         if (level1.productType2.length) {
    //           level1.productType2.map((level2) => {
    //             level2.value = level2.typeId;
    //             level2.label = level2.typeName;
    //             level2.children = level2.productType3;
    //             if (level2.productType3.length) {
    //               level2.productType3.map((level3) => {
    //                 level3.value = level3.typeId;
    //                 level3.label = level3.typeName;
    //               });
    //             }
    //           });
    //         }
    //       });
    //     }
    //     this.businessType = copyData;
    //   });
    // },

    /**
     * 获取被举报的帖子
     */
    initRoportPostData(currentPage) {
      // 传值
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
        page: currentPage,
      };
      this.currentPage = currentPage;
      // id  js转换， '',0,NaN,null,undefined转换为false，常用if条件判断
      //id文本框绑定一个值，判断是否输入了值，
      //如果有，就在接口参数中加对应的字段，没有接口参数中就不写
      if (this.searchId) {
        params.postId = this.searchId;
      }
      // //业务类型
      if (this.productTypes) {
        params.productTypes = this.productTypes;
      }
      //审核时间
      if (this.createTime) {
        params.createTime = this.createTime;
      }
      // 举报时间
      if (this.reportTime) {
        params.reportTime = this.reportTime;
      }
      //二级导航栏第一部分
      if (this.initRoportPost.postType !== "") {
        params.postType = this.initRoportPost.postType;
      }
      //二级导航栏第二部分
      if (this.initRoportPost.status !== "") {
        params.status = this.initRoportPost.status;
      }

      /**
       * 获取自己举报的帖子
       */
      api.getReportPosts(params).then((res) => {
        // console.log("获取举报帖子成功", res);
        this.tableData = res.data.pageList;
        this.reportTotal = res.data.total;
      });
    },

    //     展示选中产品类型
    getProductType(val) {
      // console.log("val ->", val[0]);
      this.productTypes = val[0];
    },

    //搜索功能
    searchReportPosts() {
      // console.log("测试");
      // id
      this.searchId
        ? (this.initRoportPost.postId = this.searchId)
        : delete this.initRoportPost.postId;
      // 业务类型
      this.productTypes
        ? (this.initRoportPost.productTypes = this.productTypes)
        : delete this.initRoportPost.productTypes;
      // 创建时间
      this.createTime
        ? (this.initRoportPost.createTime = this.createTime)
        : delete this.initRoportPost.createTime;
      // 举报时间
      this.reportTime
        ? (this.initRoportPost.reportTime = this.reportTime)
        : delete this.initRoportPost.reportTime;
      this.initRoportPostData(1);
    },

    // 从本商会删除该帖、从所有系统撤回该帖
    async removePostIds(row) {
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        let msgText = "";
        if (row.chamberId === this.chamberId) msgText = this.$t("ConfirmDelete2");
        else msgText = this.$t("ConfirmDelete");
  
        const confirmResult = await this.$confirm(msgText, this.$t("Attention"), {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancle"),
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).catch((error) => error);
        if (confirmResult !== "confirm") {
          return this.$message.info(this.$t("Canceled"));
        }
        // 发起请求 传参
        const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.id;
        const chamberId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.chamberId;
        const params = {
          currentAdmin: currentAdminId,
          chamberId: chamberId,
        };
        params.barId = row.postId;
        if (row.chamberId === this.chamberId) params.type = 2;
        else params.type = 1;
        api.getDeleteById(params).then((res) => {
          if (res.code !== 200) {
            this.$message.error(this.$t("SysBusy"));
          }
          // 成功给个提示信息
          this.$message.success(this.$t("Deleted"));
          // 刷新页面调用获取表格数据的方法
          // 刷新页面
          this.initRoportPostData(1);
        });
      }
    },

    // 举报无效功能
    invalidReport(id) {
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
            .adminData.id;
        const params = {
          currentAdmin: currentAdminId,
        };
        params.reportId = id;
        api.getUpdateId(params).then((res) => {
          // 通过状态去改变功能的显示与隐藏
          /**
           * '0正在审核 1举报成功 2举报失败 3举报撤销
           */
          if (res.code !== 200 || res.data === false) {
            this.$message.error(this.$t("SysBusy"));
          } else {
            // 成功给个提示信息
            this.$message.success(this.$t("InvalidReport"));
          }
          // 刷新页面调用获取表格数据的方法 getUserList()
          // 刷新页面
          this.initRoportPostData(1);
        });
      }
    },

    //点击切换效果 全部-卖家区
    replace(i) {
      this.initRoportPost.postType = i;
      this.initRoportPostData(1);
    },
    //点击切换效果 全部-举报无效
    select(i) {
      this.initRoportPost.status = i;
      this.initRoportPostData(1);
    },

    // 通过状态显示字体
    getPostType(i) {
      // 待审核
      if (i === 0) {
        return this.$t("Auditing");
      } else if (i === 1) {
        // 已删除
        return this.$t("Deleted");
      } else if (i === 2) {
        // 举报无效
        return this.$t("DoInvalidReport");
      } else if (i === 3) {
        // 用户已撤销
        return this.$t("Withdraw");
      }
    },

    // 跳转到详情页面
    delatied(row) {
      this.$router.push({
        name: "DetailedInformation",
        query: {
          id: row.postId,
          postType: row.postType,
          isUser: 2, // 0 从接收帖子来   1 从成员帖子传过来   2 从举报帖子来
          routerName: "ReportPost", //详情后退传参
          postOwnerId: row.postOwnerId,
          reportId: row.reportId,
        },
      });
    },

    //分页
    handleCurrentChange(currentPage) {
      console.log(currentPage);
      this.currentPage = currentPage;
      this.initRoportPostData(currentPage);
    },
  },
};
</script>
<style lang="scss" scoped>
// 大div
.refuse-div {
  // border:1px solid rgb(216, 210, 210);
  // overflow: hidden;
  margin: -10px;

  // 第一部分
  .one-part {
    border-radius: 5px;
    width: 98%;
    //margin-left: 10px;
    margin: 10px;
    font-size: 16px;
    min-height: 100px;
    padding: 5px;
    background-color: #677d95;
    color: white;
    display: flex;
    flex-direction: column;
    input {
      min-width: 200px;
      border: 1px solid white;
      margin: 8px 0;
      float: left;
    }
    div label {
      margin: 8px 0 8px 10px;
      display: block;
      float: left;
    }
    // 左边
    .one-left {
      min-height: 40px;
      display: flex;
      width: 750px;
      // id和业务类型
      .one-line {
        flex: 1;
        .text-line {
          width: 230px;
          min-height: 30px;
          font-size: 16px;
          border: 1px solid white;
          background-color: white;
          outline: none;
        }
      }
      .product-type {
        .selector {
          width: 200px;
        }
        ::v-deep.el-cascader-panel {
          display: flex;
          border-radius: 4px;
          font-size: 14px;
        }

        .el-cascader-menu:last-child .el-cascader-node {
          padding-right: 20px;
        }

        ::v-deep.el-cascader-menu__list {
          position: relative;
          min-height: 100%;
          margin: 0;
          padding: 6px 0;
          list-style: none;
          box-sizing: border-box;
        }

        ::v-deep.el-cascader {
          min-width: 200px;
          margin-right: 20px;
        }

        ::v-deep.el-scrollbar__wrap {
          overflow: hidden;
          margin-bottom: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
    .one-times {
      min-height: 40px;
      display: flex;
      width: 800px;
      margin-top: 10px;
      .times-line {
        flex: 1;
      }
    }
  }
  .search-lines {
    width: 180px;
    min-height: 70px;
    line-height: 70px;
    text-align: center;
    position: relative;
    top: -95px;
    left: 1200px;
    //text-align: center;
    .el-button {
      margin: 5px 10px;
      width: 180px;
      min-height: 70px;
      line-height: 0.5px;
      background-color: #677d95;
      color: white;
      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }
  .all-line {
    min-height: 60px;
    width: 98%;
    text-align: center;
    line-height: 55px;
    // height: 8.75rem;
    margin-left: 10px;
    margin-top: -73px;
    cursor: pointer;
    .active {
      background-color: #677d95;
      color: white;
    }
    .all-one-line {
      display: flex;
      height: 40px;
      line-height: 40px;
      border: 1px solid black;
      .all {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .parter {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .maijia {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .selelr {
        flex: 1;
      }
    }
    .two-part {
      display: flex;
      height: 40px;
      line-height: 40px;
      border: 1px solid black;
      margin-top: 5px;
      .two-all {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .two-sh {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .two-passed {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .refuses {
        flex: 1;
      }
    }
  }
  .el-table-th {
    background-color: #677d95;
    color: white;
    opacity: 1;
  }
  .table {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .report-reason {
    width: 220px;
    overflow: hidden;
    word-break: break-word; //不截断英文单词
    text-overflow: ellipsis; //当元素内文本溢出时（超过width部分）显示省略标记（...）
    white-space: nowrap;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .export-excel-wrapper {
    float: left;
  }
  .table-border {
    width: 98%;
    margin: 8px 10px;
    .deleted {
      color: #b6c437;
    }
    .reviewed {
      color: #cba43f;
    }
    .refused {
      color: #951d1d;
    }
    .other {
      color: #951d1d;
    }
  }
  .fen-pages {
    margin-top: 10px;
  }
}
</style>